.hero {
  background: linear-gradient(
    79.09deg,
    hsl(var(--color-theme) 100 23) 13.73%,
    hsl(var(--color-theme) 23 60) 93.57%
  );
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),
    url("https://drevniy-rim.files2.space/storage/vault/files/d/95/d7227a7b-8ad3-4654-84b0-9bd62edfad95.d7227a7b-8ad3-4654-84b0-9_y6BtJrt.webp");
  background-size: cover; */
}

.hero__block {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--space-l);
  padding-block: var(--space-xl);

  @media (max-width: 64em) {
    flex-direction: column-reverse;
  }
}

.hero__h1 {
  font-size: var(--step-4);
  font-weight: 800;
  color: #fff;

  & span {
    display: block;
    font-size: var(--step-1);
    font-weight: 400;
  }
}

.hero__note {
  margin-top: var(--space-m);
  line-height: var(--font-lineheight-4);
  color: #fff;
}

.hero_btn {
  margin-top: var(--space-l);
  & a {
    display: inline-block;
    padding: 30px 109px;
    gap: 0px;
    border-radius: 3px;
    opacity: 0px;
    background: linear-gradient(
      30.17deg,
      hsl(var(--color-theme) 100 23) 0%,
      hsl(var(--color-theme) 23 60) 100%
    );
    box-shadow: 2px 2px 4px 0px #00000033;
    color: #fff;
    font-size: var(--step-1);
    font-weight: 700;
    text-decoration: none;
    transition: 0.5s;

    @media (max-width: 64em) {
      display: block;
      text-align: center;
      padding: 30px 0;
    }
  }
  & a:hover {
    background: linear-gradient(
      130.17deg,
      hsl(var(--color-theme) 100 23) 0%,
      hsl(var(--color-theme) 23 60) 100%
    );
    transition: 0.5s;
  }
}

.hero__logo {
  margin-inline: auto;
  max-width: 450px;

  @media (max-width: 64em) {
    max-width: 250px;
  }
}
