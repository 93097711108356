.slider {
  margin-top: var(--space-2xl);
}

.slider__block {
  margin-top: var(--space-l);

  & p {
    margin-top: var(--space-xl);
    font-size: var(--step-0);
    font-weight: 300;
    line-height: 36px;
    text-align: center;
  }

  & .slider_btn {
    text-align: center;
    margin-top: var(--space-l);
  }
}

.scroll-snap-slider.-simple {
  --slider-w: 800px;
  --slider-h: 600px;
  width: var(--slider-w);
  height: var(--slider-h);
  margin: 1rem auto;
  border-radius: 5px;
  box-shadow: 0 20px 50px 0 #d9d9d9;

  @media (max-width: 70em) {
    --slider-w: 100%;
    --slider-h: 300px;
  }

  &:not(.-show-scroll-bar) {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.scroll-snap-slider {
  display: flex;
  flex-wrap: nowrap;
  justify-content: normal;
  overflow-x: auto;
  padding-inline: 0;
  scroll-behavior: smooth;
  scroll-snap-stop: always;
  scroll-snap-type: x mandatory;

  &.-simple .scroll-snap-slide {
    scroll-snap-align: start;
    width: var(--slider-w);
  }
}

.scroll-snap-slide {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  max-width: none;
  scroll-snap-align: start;
  width: 100%;
}

.-simple img {
  width: var(--slider-w);
  height: var(--slider-h);
  object-fit: cover;
  border-radius: 10px;
}
