.news {
  margin-top: var(--space-2xl);
}

.news__list {
  margin-top: var(--space-2xl);
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: var(--space-s);

  @media (max-width: 64em) {
    flex-direction: column;
  }

  & .news__col {
    width: 100%;
    max-width: 24%;
    padding: var(--space-s) var(--space-s);
    background: linear-gradient(180deg, #ffffff 0%, #f6f6f6 100%);
    box-shadow: 0 20px 50px 0 #d9d9d9;

    @media (max-width: 64em) {
      flex-direction: column;
      max-width: 100%;
    }

    & a {
      color: initial;
      text-decoration: none;
      transition: 0.5s;

      &:hover {
        color: hsl(var(--color-theme) 40 30);
        transition: 0.5s;
      }
    }

    & .news__date {
      font-size: var(--step--2);
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      color: var(--color-text-light-gray);
    }

    & h3.card__title {
      font-size: 18px;
      font-weight: 700;
      line-height: var(--font-lineheight-4);
      letter-spacing: 0.69px;
      text-align: left;
    }

    & img.news__img {
      margin-top: var(--space-s);
      border-radius: var(--space-3xs);
    }

    & p.news__text {
      margin-block: var(--space-s);
      font-size: var(--step--2);
      font-weight: 400;
      line-height: 22.96px;
      text-align: left;
    }
  }
}

.news__link {
  text-align: center;
  margin-top: var(--space-l);
  & > a {
    color: var(--color-text-gray);
    font-size: var(--step--1);
    font-weight: 700;
    text-decoration: none;
  }
}
