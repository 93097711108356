* {
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

:root {
  --color-bg-gray: #fefefe;
  --color-text: #1a1a1a;
  --color-text-gray: #5d5d5d;
  --color-text-light-gray: #8f8f8f;
  --color-theme: 111;
  --color-green: #76b83f;
  --color-green-hover: #489f00;
  --color-blue: #4387e2;
  --color-yellow: #f7ac22;
  --color-bg-footer: #141414;
  --font-sans: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
  --font-serif: ui-serif, serif;
  --font-mono: Dank Mono, Operator Mono, Inconsolata, Fira Mono, ui-monospace, SF Mono, Monaco, Droid Sans Mono, Source Code Pro, monospace;
  --font-manrope: Manrope, sans-serif;
  --font-inter: "Roboto", sans-serif;
  --font-lineheight-00: .95;
  --font-lineheight-0: 1.1;
  --font-lineheight-1: 1.25;
  --font-lineheight-2: 1.375;
  --font-lineheight-4: 1.75;
  --font-letterspacing-2: .05em;
  --step--2: clamp(.78rem, calc(.77rem + .03vw), .8rem);
  --step--1: clamp(.94rem, calc(.92rem + .11vw), 1rem);
  --step-0: clamp(1.13rem, calc(1.08rem + .22vw), 1.25rem);
  --step-1: clamp(1.35rem, calc(1.28rem + .37vw), 1.56rem);
  --step-2: clamp(1.62rem, calc(1.5rem + .58vw), 1.95rem);
  --step-3: clamp(1.94rem, calc(1.77rem + .87vw), 2.44rem);
  --step-4: clamp(2.33rem, calc(2.08rem + 1.25vw), 3.05rem);
  --space-3xs: clamp(.31rem, calc(.31rem + 0vw), .31rem);
  --space-2xs: clamp(.56rem, calc(.54rem + .11vw), .63rem);
  --space-xs: clamp(.88rem, calc(.85rem + .11vw), .94rem);
  --space-s: clamp(1.13rem, calc(1.08rem + .22vw), 1.25rem);
  --space-m: clamp(1.69rem, calc(1.62rem + .33vw), 1.88rem);
  --space-l: clamp(2.25rem, calc(2.16rem + .43vw), 2.5rem);
  --space-xl: clamp(3.38rem, calc(3.24rem + .65vw), 3.75rem);
  --space-2xl: clamp(4.5rem, calc(4.33rem + .87vw), 5rem);
  --space-s-m: clamp(1.13rem, calc(.86rem + 1.3vw), 1.88rem);
  --space-xl-2xl: clamp(3.38rem, calc(2.81rem + 2.83vw), 5rem);
  --space-s-l: clamp(1.13rem, calc(.65rem + 2.39vw), 2.5rem);
  --grid-max-width: 1140px;
  --grid-gutter: var(--space-s-l, clamp(1.13rem, calc(.65rem + 2.39vw), 2.5rem));
  --grid-columns: 12;
}

html, body {
  background: var(--color-bg-gray);
  font-family: var(--font-inter);
  font-size: 100%;
  font-weight: 400;
  line-height: var(--font-lineheight-0);
  color: var(--color-text);
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*, :before, :after {
  box-sizing: inherit;
}

ol, ul {
  list-style: none;
}

img {
  object-fit: cover;
  width: 100%;
}

.u-grid {
  gap: var(--grid-gutter);
  display: grid;
}

.container {
  max-width: var(--grid-max-width);
  padding-inline: var(--grid-gutter);
  margin-inline: auto;
}

.container-small {
  padding-inline: var(--grid-gutter);
  max-width: 1000px;
  margin-inline: auto;
}

.hidden {
  display: none;
}

.section-gray {
  padding-top: var(--space-l);
  padding-bottom: var(--space-2xl);
  background: linear-gradient(64.37deg, #c4c4c940 1%, #f7f7f7 53%, #c4c4c940 99%);
}

.bg-card-gradient {
  background: linear-gradient(#fff 0%, #f6f6f6 100%);
}

.card-shadow {
  box-shadow: 0 20px 50px #d9d9d9;
}

.title {
  font-family: var(--font-inter);
  font-size: var(--step-4);
  letter-spacing: .75px;
  text-align: center;
  font-weight: 700;
  line-height: 72.61px;
}

h2 {
  margin-top: var(--space-m);
  font-size: var(--step-2);
  font-weight: 400;
  line-height: var(--font-lineheight-2);
  text-align: center;
}

.green__btn {
  color: #fff;
  background-color: hsl(var(--color-theme) 100 28);
  font-size: var(--step-1);
  border-radius: 3px;
  text-decoration: none;
  transition: all .5s;
  display: inline-block;
}

.green__btn:hover {
  background-color: hsl(var(--color-theme) 60 40);
  transition: all .5s;
  transform: translate(-1px, -1px);
  box-shadow: 3px 3px 15px #d1d1d3;
}

.green__btn--big {
  padding: 27px 110px;
  font-weight: 700;
}

@media (width <= 64em) {
  .green__btn--big {
    padding: 27px 0;
    display: block;
  }
}

.green__btn--base {
  padding: 8px 26px;
  font-weight: 400;
}

.bg-theme-block {
  padding-block: var(--space-l);
  background-color: hsl(var(--color-theme) 20 60);
  min-height: 400px;
  margin-top: 400px;
  position: relative;
}

header {
  background-color: var(--color-bg-gray);
  width: 100%;
  position: sticky;
  top: 0;
  box-shadow: 0 53px 300px -25px #000000bf;
}

.header__row {
  justify-content: space-between;
  align-items: center;
  height: 86px;
  display: flex;
}

header nav {
  display: flex;
}

@media (width <= 64em) {
  header nav {
    display: none;
  }
}

header nav a {
  font-family: var(--font-inter);
  color: #000;
  text-transform: uppercase;
  margin-left: var(--space-l);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-decoration: none;
}

.header__logo img {
  width: 62px;
}

.navbar__mobile {
  display: none;
}

@media (width <= 64em) {
  .navbar__mobile {
    display: block;
  }
}

.hero {
  background: linear-gradient(79.09deg, hsl(var(--color-theme) 100 23) 13.73%, hsl(var(--color-theme) 23 60) 93.57%);
}

.hero__block {
  justify-content: space-around;
  align-items: center;
  gap: var(--space-l);
  padding-block: var(--space-xl);
  display: flex;
}

@media (width <= 64em) {
  .hero__block {
    flex-direction: column-reverse;
  }
}

.hero__h1 {
  font-size: var(--step-4);
  color: #fff;
  font-weight: 800;
}

.hero__h1 span {
  font-size: var(--step-1);
  font-weight: 400;
  display: block;
}

.hero__note {
  margin-top: var(--space-m);
  line-height: var(--font-lineheight-4);
  color: #fff;
}

.hero_btn {
  margin-top: var(--space-l);
}

.hero_btn a {
  opacity: 0px;
  background: linear-gradient(30.17deg, hsl(var(--color-theme) 100 23) 0%, hsl(var(--color-theme) 23 60) 100%);
  color: #fff;
  font-size: var(--step-1);
  border-radius: 3px;
  gap: 0;
  padding: 30px 109px;
  font-weight: 700;
  text-decoration: none;
  transition: all .5s;
  display: inline-block;
  box-shadow: 2px 2px 4px #0003;
}

@media (width <= 64em) {
  .hero_btn a {
    text-align: center;
    padding: 30px 0;
    display: block;
  }
}

.hero_btn a:hover {
  background: linear-gradient(130.17deg, hsl(var(--color-theme) 100 23) 0%, hsl(var(--color-theme) 23 60) 100%);
  transition: all .5s;
}

.hero__logo {
  max-width: 450px;
  margin-inline: auto;
}

@media (width <= 64em) {
  .hero__logo {
    max-width: 250px;
  }
}

.slider {
  margin-top: var(--space-2xl);
}

.slider__block {
  margin-top: var(--space-l);
}

.slider__block p {
  margin-top: var(--space-xl);
  font-size: var(--step-0);
  text-align: center;
  font-weight: 300;
  line-height: 36px;
}

.slider__block .slider_btn {
  text-align: center;
  margin-top: var(--space-l);
}

.scroll-snap-slider.-simple {
  --slider-w: 800px;
  --slider-h: 600px;
  width: var(--slider-w);
  height: var(--slider-h);
  border-radius: 5px;
  margin: 1rem auto;
  box-shadow: 0 20px 50px #d9d9d9;
}

@media (width <= 70em) {
  .scroll-snap-slider.-simple {
    --slider-w: 100%;
    --slider-h: 300px;
  }
}

.scroll-snap-slider.-simple:not(.-show-scroll-bar) {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll-snap-slider {
  scroll-behavior: smooth;
  scroll-snap-stop: always;
  scroll-snap-type: x mandatory;
  flex-wrap: nowrap;
  justify-content: normal;
  padding-inline: 0;
  display: flex;
  overflow-x: auto;
}

.scroll-snap-slider.-simple .scroll-snap-slide {
  scroll-snap-align: start;
  width: var(--slider-w);
}

.scroll-snap-slide {
  scroll-snap-align: start;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: none;
  display: flex;
}

.-simple img {
  width: var(--slider-w);
  height: var(--slider-h);
  object-fit: cover;
  border-radius: 10px;
}

.features {
  position: absolute;
  top: -280px;
  left: 0;
  right: 0;
}

.features__list {
  margin-top: var(--space-2xl);
  background-color: #fff;
  border-radius: 10px;
  flex-direction: row;
  display: flex;
  box-shadow: 0 0 5px 5px #00000006;
}

@media (width <= 64em) {
  .features__list {
    flex-direction: column;
  }
}

.features__list .col {
  padding: var(--space-s);
  align-items: center;
  width: 100%;
  display: flex;
}

@media (width <= 64em) {
  .features__list .col {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.features__list .col .svg {
  color: var(--color-text-light-gray);
}

.features__list .col .svg svg {
  margin-right: var(--space-m);
  fill: currentColor;
  width: 64px;
  height: 64px;
}

@media (width <= 64em) {
  .features__list .col .svg svg {
    margin-right: 0;
    margin-bottom: var(--space-s);
  }
}

.features__list .col .right {
  width: 100%;
}

.features__list .col .right h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: var(--font-lineheight-2);
  text-align: left;
}

@media (width <= 64em) {
  .features__list .col .right h3 {
    text-align: center;
  }
}

.features__list .col .right p {
  margin-top: var(--space-s);
  font-weight: 300;
  line-height: var(--font-lineheight-2);
  text-align: left;
}

@media (width <= 64em) {
  .features__list .col .right p {
    text-align: center;
  }
}

.card-b {
  margin-top: var(--space-2xl);
}

.card-b .card-b__block {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (width <= 64em) {
  .card-b .card-b__block {
    flex-direction: column-reverse;
  }
}

.card-b .card-b__block .card-b__text {
  color: #fff;
  width: 100%;
}

.card-b .card-b__block .card-b__text h2 {
  text-align: left;
  font-size: var(--step-2);
  font-weight: 700;
}

.card-b .card-b__block .card-b__text p {
  margin-top: var(--space-m);
  font-size: var(--step-0);
  font-weight: 300;
  line-height: var(--font-lineheight-2);
}

.card-b .card-b__block .card-b__text .card-b__btn {
  margin-top: var(--space-l);
}

.card-b .card-b__block .card-b__text .card-b__btn a {
  text-align: center;
}

.card-b .card-b__block .card-b__rating {
  text-align: center;
  color: #fff;
  width: 50%;
}

.card-b .card-b__block .card-b__rating p {
  font-size: var(--step-0);
  text-align: center;
}

.card-b .card-b__block .card-b__rating .card-b__num {
  margin-block: var(--space-2xs);
  font-size: 72px;
  font-weight: 700;
}

.card-b .card-b__block .card-b__rating .card-b__stars {
  justify-content: center;
  display: flex;
}

.card-b .card-b__block .card-b__rating .card-b__stars svg {
  color: var(--color-yellow);
  width: 48px;
  height: 48px;
}

@media (width <= 64em) {
  .card-b .card-b__block .card-b__rating {
    width: 100%;
  }
}

.reviews {
  margin-top: var(--space-2xl);
}

.reviews__list {
  margin-top: var(--space-2xl);
  justify-content: center;
  gap: var(--space-s);
  flex-direction: row;
  display: flex;
}

@media (width <= 64em) {
  .reviews__list {
    flex-direction: column;
  }
}

.reviews__list .reviews__col {
  padding: var(--space-s) var(--space-s);
  background: linear-gradient(#fff 0%, #f6f6f6 100%);
  width: 100%;
  max-width: 33%;
  box-shadow: 0 5px 20px #d9d9d9;
}

@media (width <= 64em) {
  .reviews__list .reviews__col {
    flex-direction: column;
    max-width: 100%;
  }
}

.reviews__list .reviews__col p.reviews__text {
  margin-top: var(--space-s);
  font-size: var(--step--2);
  text-align: left;
  font-weight: 400;
  line-height: 22.96px;
}

.reviews__item {
  gap: var(--space-2xs);
  align-items: center;
  display: flex;
}

.reviews__item .reviews__user {
  font-size: 18px;
  font-weight: 700;
  line-height: var(--font-lineheight-4);
  letter-spacing: .69px;
  text-align: left;
}

.reviews__item img.reviews__img {
  --size-avatar: 50px;
  width: var(--size-avatar);
  height: var(--size-avatar);
  border-radius: 50%;
}

.reviews__item .reviews__star {
  color: var(--color-yellow);
  gap: 2px;
  display: flex;
}

.reviews__item .reviews__star svg {
  width: 18px;
  height: 18px;
}

.news {
  margin-top: var(--space-2xl);
}

.news__list {
  margin-top: var(--space-2xl);
  justify-content: center;
  gap: var(--space-s);
  flex-direction: row;
  display: flex;
}

@media (width <= 64em) {
  .news__list {
    flex-direction: column;
  }
}

.news__list .news__col {
  padding: var(--space-s) var(--space-s);
  background: linear-gradient(#fff 0%, #f6f6f6 100%);
  width: 100%;
  max-width: 24%;
  box-shadow: 0 20px 50px #d9d9d9;
}

@media (width <= 64em) {
  .news__list .news__col {
    flex-direction: column;
    max-width: 100%;
  }
}

.news__list .news__col a {
  color: initial;
  text-decoration: none;
  transition: all .5s;
}

.news__list .news__col a:hover {
  color: hsl(var(--color-theme) 40 30);
  transition: all .5s;
}

.news__list .news__col .news__date {
  font-size: var(--step--2);
  text-align: left;
  color: var(--color-text-light-gray);
  font-weight: 400;
  line-height: 21px;
}

.news__list .news__col h3.card__title {
  font-size: 18px;
  font-weight: 700;
  line-height: var(--font-lineheight-4);
  letter-spacing: .69px;
  text-align: left;
}

.news__list .news__col img.news__img {
  margin-top: var(--space-s);
  border-radius: var(--space-3xs);
}

.news__list .news__col p.news__text {
  margin-block: var(--space-s);
  font-size: var(--step--2);
  text-align: left;
  font-weight: 400;
  line-height: 22.96px;
}

.news__link {
  text-align: center;
  margin-top: var(--space-l);
}

.news__link > a {
  color: var(--color-text-gray);
  font-size: var(--step--1);
  font-weight: 700;
  text-decoration: none;
}

.props {
  margin-top: 150px;
}

.props__block {
  margin-top: var(--space-l);
  width: 100%;
  max-width: 600px;
  margin-inline: auto;
}

.props__list {
  padding: var(--space-2xs) var(--space-s);
  border-bottom: 1px solid #eaecef;
  justify-content: space-between;
  display: flex;
}

.props__info {
  margin-top: var(--space-l);
  gap: var(--space-m);
  justify-content: space-around;
  display: flex;
}

.info__reviews > div.info__rating {
  justify-content: center;
  align-items: center;
  display: flex;
}

.info__reviews > div.info__rating > div {
  font-size: var(--step-1);
  font-weight: 700;
}

.info__reviews > div.info__rating svg {
  width: 24px;
  height: 24px;
  margin-left: 5px;
}

.info__reviews > div.info__count {
  justify-content: center;
  align-items: center;
  display: flex;
}

.info__reviews > div.info__count > div {
  padding-block: 4px;
}

.info__reviews > div.info__count a {
  color: var(--color-blue);
}

.info__reviews > div.info__count a svg {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}

.info__downloads > div:last-child {
  padding-block: 4px;
}

.info__downloads .down__count {
  text-align: center;
  font-size: var(--step-1);
  font-weight: 700;
}

.info__age > img {
  width: 40px;
}

.props_btn {
  text-align: center;
  margin-top: var(--space-2xl);
}

.footer {
  margin-top: var(--space-l);
  background-color: var(--color-bg-footer);
  padding-block: var(--space-l);
  width: 100%;
}

.footer__blocks {
  justify-content: center;
  align-items: center;
  gap: var(--space-l);
  display: flex;
}

@media (width <= 64em) {
  .footer__blocks {
    flex-direction: column;
  }
}

.footer__blocks a {
  color: var(--color-text-light-gray);
  font-size: var(--step--1);
  text-decoration: none;
}
/*# sourceMappingURL=card.3185d897.css.map */
