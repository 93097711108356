.props {
  margin-top: 150px;
}

.props__block {
  margin-top: var(--space-l);
  margin-inline: auto;
  width: 100%;
  max-width: 600px;
}

.props__list {
  display: flex;
  justify-content: space-between;
  padding: var(--space-2xs) var(--space-s);
  border-bottom: 1px solid #eaecef;
}

.props__info {
  margin-top: var(--space-l);
  display: flex;
  gap: var(--space-m);
  justify-content: space-around;
}

.info__reviews {
  & > div.info__rating {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > div.info__rating > div {
    font-size: var(--step-1);
    font-weight: 700;
  }
  & > div.info__rating svg {
    margin-left: 5px;
    width: 24px;
    height: 24px;
  }

  & > div.info__count {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      padding-block: 4px;
    }

    & a {
      color: var(--color-blue);

      & svg {
        margin-left: 5px;
        width: 18px;
        height: 18px;
      }
    }
  }
}

.info__downloads {
  & > div:last-child {
    padding-block: 4px;
  }

  & .down__count {
    text-align: center;
    font-size: var(--step-1);
    font-weight: 700;
  }
}

.info__age > img {
  width: 40px;
}

.props_btn {
  text-align: center;
  margin-top: var(--space-2xl);
}
