.reviews {
  margin-top: var(--space-2xl);
}

.reviews__list {
  margin-top: var(--space-2xl);
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: var(--space-s);

  @media (max-width: 64em) {
    flex-direction: column;
  }

  & .reviews__col {
    width: 100%;
    max-width: 33%;
    padding: var(--space-s) var(--space-s);
    background: linear-gradient(180deg, #ffffff 0%, #f6f6f6 100%);
    box-shadow: 0 5px 20px 0 #d9d9d9;

    @media (max-width: 64em) {
      flex-direction: column;
      max-width: 100%;
    }

    & p.reviews__text {
      margin-top: var(--space-s);
      font-size: var(--step--2);
      font-weight: 400;
      line-height: 22.96px;
      text-align: left;
    }
  }
}

.reviews__item {
  display: flex;
  gap: var(--space-2xs);
  align-items: center;

  & .reviews__user {
    font-size: 18px;
    font-weight: 700;
    line-height: var(--font-lineheight-4);
    letter-spacing: 0.69px;
    text-align: left;
  }

  & img.reviews__img {
    --size-avatar: 50px;
    width: var(--size-avatar);
    height: var(--size-avatar);
    border-radius: 50%;
  }

  & .reviews__star {
    display: flex;
    gap: 2px;
    color: var(--color-yellow);

    & svg {
      width: 18px;
      height: 18px;
    }
  }
}
