.features {
  position: absolute;
  top: -280px;
  left: 0;
  right: 0;
}

.features__list {
  margin-top: var(--space-2xl);
  display: flex;
  /* gap: var(--space-s); */
  flex-direction: row;
  background-color: #fff;
  box-shadow: 0px 0px 5px 5px #00000006;
  border-radius: 10px;

  @media (max-width: 64em) {
    flex-direction: column;
  }

  & .col {
    width: 100%;
    display: flex;
    align-items: center;
    padding: var(--space-s);

    @media (max-width: 64em) {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    & .svg {
      color: var(--color-text-light-gray);

      & svg {
        margin-right: var(--space-m);
        fill: currentColor;
        width: 64px;
        height: 64px;

        @media (max-width: 64em) {
          margin-right: 0;
          margin-bottom: var(--space-s);
        }
      }
    }

    & .right {
      width: 100%;

      & h3 {
        font-size: 18px;
        font-weight: 500;
        line-height: var(--font-lineheight-2);
        text-align: left;

        @media (max-width: 64em) {
          text-align: center;
        }
      }

      & p {
        margin-top: var(--space-s);
        font-weight: 300;
        line-height: var(--font-lineheight-2);
        text-align: left;

        @media (max-width: 64em) {
          text-align: center;
        }
      }
    }
  }
}
