html,
body {
  background: var(--color-bg-gray);
  font-family: var(--font-inter);
  font-size: 100%;
  font-weight: 400;
  line-height: var(--font-lineheight-0);
  color: var(--color-text);
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

ol,
ul {
  list-style: none;
}

img {
  width: 100%;
  object-fit: cover;
}

.u-grid {
  display: grid;
  gap: var(--grid-gutter);
}

.container {
  max-width: var(--grid-max-width);
  padding-inline: var(--grid-gutter);
  margin-inline: auto;
}

.container-small {
  max-width: 1000px;
  padding-inline: var(--grid-gutter);
  margin-inline: auto;
}

.hidden {
  display: none;
}

/*----------  Subsection comment block  ----------*/

.section-gray {
  padding-top: var(--space-l);
  padding-bottom: var(--space-2xl);
  background: linear-gradient(
    64.37deg,
    rgba(196, 196, 201, 0.25) 1%,
    #f7f7f7 53%,
    rgba(196, 196, 201, 0.25) 99%
  );
}

.bg-card-gradient {
  background: linear-gradient(180deg, #ffffff 0%, #f6f6f6 100%);
}

.card-shadow {
  box-shadow: 0px 20px 50px 0px #d9d9d9;
}

/*----------  Subsection comment block  ----------*/

.title {
  font-family: var(--font-inter);
  font-size: var(--step-4);
  font-weight: 700;
  line-height: 72.61px;
  letter-spacing: 0.75px;
  text-align: center;
}

h2 {
  margin-top: var(--space-m);
  font-size: var(--step-2);
  font-weight: 400;
  line-height: var(--font-lineheight-2);
  text-align: center;
}

.green__btn {
  display: inline-block;
  color: #ffffff;
  text-decoration: none;
  background-color: hsl(var(--color-theme) 100 28);
  border-radius: 3px;
  transition: 0.5s;
  font-size: var(--step-1);

  &:hover {
    background-color: hsl(var(--color-theme) 60 40);
    transform: translate(-1px, -1px);
    box-shadow: 3px 3px 15px #d1d1d3;
    transition: 0.5s;
  }
}

.green__btn--big {
  padding: 27px 110px;
  font-weight: 700;

  @media (max-width: 64em) {
    display: block;
    padding: 27px 0;
  }
}

.green__btn--base {
  padding: 8px 26px;
  font-weight: 400;
}

.bg-theme-block {
  margin-top: 400px;
  padding-block: var(--space-l);
  position: relative;
  background-color: hsl(var(--color-theme) 20 60);
  min-height: 400px;
}
