:root {
  --color-bg-gray: #fefefe;
  --color-text: #1a1a1a;
  --color-text-gray: #5d5d5d;
  --color-text-light-gray: #8f8f8f;
  --color-theme: 111;
  /* 111 зеленый */
  /* 222 синий */
  /* 333 бардовый  */
  /* 272 фиолетовый  */
  /* 5 темно красный  */
  /* 47 коричнево желтый  */
  /* 33 коричнево желтый  */
  --color-green: #76b83f;
  --color-green-hover: #489f00;
  --color-blue: #4387e2;
  --color-yellow: #f7ac22;
  --color-bg-footer: #141414;

  --font-sans: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell,
    Noto Sans, sans-serif;
  --font-serif: ui-serif, serif;
  --font-mono: Dank Mono, Operator Mono, Inconsolata, Fira Mono, ui-monospace,
    SF Mono, Monaco, Droid Sans Mono, Source Code Pro, monospace;
  --font-manrope: Manrope, sans-serif;

  --font-inter: "Roboto", sans-serif;

  --font-lineheight-00: 0.95;
  --font-lineheight-0: 1.1;
  --font-lineheight-1: 1.25;
  --font-lineheight-2: 1.375;
  --font-lineheight-4: 1.75;

  --font-letterspacing-2: 0.05em;

  --step--2: clamp(0.78rem, calc(0.77rem + 0.03vw), 0.8rem);
  --step--1: clamp(0.94rem, calc(0.92rem + 0.11vw), 1rem);
  --step-0: clamp(1.13rem, calc(1.08rem + 0.22vw), 1.25rem);
  --step-1: clamp(1.35rem, calc(1.28rem + 0.37vw), 1.56rem);
  --step-2: clamp(1.62rem, calc(1.5rem + 0.58vw), 1.95rem);
  --step-3: clamp(1.94rem, calc(1.77rem + 0.87vw), 2.44rem);
  --step-4: clamp(2.33rem, calc(2.08rem + 1.25vw), 3.05rem);

  /*------------------------------------*\
    # ОТСТУПЫ
  \*------------------------------------*/

  --space-3xs: clamp(0.31rem, calc(0.31rem + 0vw), 0.31rem);
  --space-2xs: clamp(0.56rem, calc(0.54rem + 0.11vw), 0.63rem);
  --space-xs: clamp(0.88rem, calc(0.85rem + 0.11vw), 0.94rem);
  --space-s: clamp(1.13rem, calc(1.08rem + 0.22vw), 1.25rem);
  --space-m: clamp(1.69rem, calc(1.62rem + 0.33vw), 1.88rem);
  --space-l: clamp(2.25rem, calc(2.16rem + 0.43vw), 2.5rem);
  --space-xl: clamp(3.38rem, calc(3.24rem + 0.65vw), 3.75rem);
  --space-2xl: clamp(4.5rem, calc(4.33rem + 0.87vw), 5rem);

  /* One-up pairs */
  --space-s-m: clamp(1.13rem, calc(0.86rem + 1.3vw), 1.88rem);
  --space-xl-2xl: clamp(3.38rem, calc(2.81rem + 2.83vw), 5rem);

  /* Custom pairs */
  --space-s-l: clamp(1.13rem, calc(0.65rem + 2.39vw), 2.5rem);

  --grid-max-width: 1140px;
  --grid-gutter: var(
    --space-s-l,
    clamp(1.13rem, calc(0.65rem + 2.39vw), 2.5rem)
  );
  --grid-columns: 12;
}
