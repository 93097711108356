.footer {
  margin-top: var(--space-l);
  width: 100%;
  background-color: var(--color-bg-footer);
  padding-block: var(--space-l);
}

.footer__blocks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--space-l);

  @media (max-width: 64em) {
    flex-direction: column;
  }

  & a {
    color: var(--color-text-light-gray);
    font-size: var(--step--1);
    text-decoration: none;
  }
}
