header {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--color-bg-gray);
  box-shadow: 0px 53px 300px -25px rgba(0, 0, 0, 0.75);
}

.header__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 86px;
}

header nav {
  display: flex;

  @media (max-width: 64em) {
    display: none;
  }

  & a {
    font-family: var(--font-inter);
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    margin-left: var(--space-l);
  }
}

.header__logo img {
  width: 62px;
}

.navbar__mobile {
  display: none;
  @media (max-width: 64em) {
    display: block;
  }
}
