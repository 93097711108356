.card-b {
  margin-top: var(--space-2xl);

  & .card-b__block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 64em) {
      flex-direction: column-reverse;
    }

    & .card-b__text {
      width: 100%;
      color: #fff;

      & h2 {
        text-align: left;
        font-size: var(--step-2);
        font-weight: 700;
      }

      & p {
        margin-top: var(--space-m);
        font-size: var(--step-0);
        font-weight: 300;
        line-height: var(--font-lineheight-2);
      }

      & .card-b__btn {
        margin-top: var(--space-l);

        & a {
          text-align: center;
        }
      }
    }

    & .card-b__rating {
      width: 50%;
      text-align: center;
      color: #fff;

      & p {
        font-size: var(--step-0);
        text-align: center;
      }

      & .card-b__num {
        font-weight: 700;
        font-size: 72px;
        margin-block: var(--space-2xs);
      }

      & .card-b__stars {
        display: flex;
        justify-content: center;

        & svg {
          color: var(--color-yellow);
          width: 48px;
          height: 48px;
        }
      }

      @media (max-width: 64em) {
        width: 100%;
      }
    }
  }
}
